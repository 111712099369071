import type {HomepageAiFeaturesModalProps} from '@Modals/homepage-ai-features-modal';
import {openHomepageAiFeaturesModal} from '@Modals/homepage-ai-features-modal';
import type {HomepageStartCreatingModalProps} from '@Modals/homepage-start-creating-modal';
import {openHomepageStartCreatingModal} from '@Modals/homepage-start-creating-modal';
import {renderElement} from '@Utils/root-element.util';
import React from 'react';
import {Dropdown} from '@Components/dropdown-v2';
import {Button, Type} from '@Components/button';
import {noop} from '@Utils/general.util';
import HomepageCreatePopupPanel from '@Panels/homepage-create-popup-panel';
import {ALIGNMENT_TYPE} from '@Components/dropdown-v2/dropdown.types';
import type {HomepageCreatePopupPanelProps} from '@Panels/homepage-create-popup-panel/homepage-create-popup-panel.types';
import type {HomepageCreatePopupModalProps} from '@Modals/homepage-create-options-modal';
import {openCreateOptionsModal} from '@Modals/homepage-create-options-modal';
import {Provider} from 'react-redux';

/**
 * @author Moeed Ahmad <moeed@250mils.com>
 *
 */
window.PMW.mergePMW(PMW, {
  openHomepageAiFeaturesModal(options: HomepageAiFeaturesModalProps) {
    openHomepageAiFeaturesModal(options);
  },

  openHomepageStartCreatingModal(options: HomepageStartCreatingModalProps) {
    openHomepageStartCreatingModal(options);
  },

  openHomepageCreateOptionsModal(options: HomepageCreatePopupModalProps) {
    options.includeHeader = true;
    openCreateOptionsModal(options);
  },

  initHomepageCreatePopup(contentContainer: HTMLElement, opts: HomepageCreatePopupPanelProps = {}) {
    renderElement(
      contentContainer,
      <Provider store={window.PMW.redux.store}>
        <Dropdown
          selector={
            <Button shortText={window.i18next.t('pmwjs_create')} text={window.i18next.t('pmwjs_create')} type={Type.PREMIUM} onClick={noop} customClasses="homepage-nav-cta" />
          }
          popup={<HomepageCreatePopupPanel {...opts} />}
          alignment={ALIGNMENT_TYPE.BOTTOM_END}
          modalTitle={window.i18next.t('pmwjs_start_creating')}
          popUpHasCustomWidth
          popupClassName="radius-8"
          shouldFlip
          shouldShift
          showModalForSmallScreen
          popupOffset={8}
        />
      </Provider>
    );
  },
});
