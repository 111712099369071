import type {BasePanel} from '@Components/panel/panel.types';

export const PANEL_ID = 'homepage-start-creating-panel';
export interface HomepageStartCreatingPanelProps extends BasePanel {
  items?: CardData[];
}

export type CardData = {
  assetPath: string;
  text: string;
  href: string;
  eventType: string;
};
