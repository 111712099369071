import '@Global/home-page';
import '@Global/create-from-scratch';

import {createRoot} from 'react-dom/client';
import App from '@Components/app';
import React from 'react';
import {ModalContainer} from '@Components/modal-container';

const a = document.createElement('div');
a.setAttribute('id', 'pmw-app');
document.body.appendChild(a);

const root = createRoot(document.getElementById('pmw-app')!);
root.render(
  <App>
    <ModalContainer />
  </App>
);
