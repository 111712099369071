import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import type {HomepageCreatePopupPanelProps} from '@Panels/homepage-create-popup-panel/homepage-create-popup-panel.types';
import {PANEL_ID} from '@Panels/homepage-create-popup-panel/homepage-create-popup-panel.types';
import {useAppSelector} from '@/hooks';

export type HomepageCreatePopupModalProps = HomepageCreatePopupPanelProps;

export default function HomepageCreateOptionsModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.HOMEPAGE_CREATE_OPTIONS_MODAL];
  });

  return (
    <Modal
      modalId={MODALS_IDS.HOMEPAGE_CREATE_OPTIONS_MODAL}
      panelId={PANEL_ID}
      modalMaxWidth="1440px"
      modalWidth="100%"
      modalHeight="fit-content"
      className="spacing-p-4 _unpadded-top"
      animatesBottomUp
      stickToBottom
      isBottomSheetOnMobile
    >
      <LazyPanel
        panelDirectoryName={PANEL_ID}
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openCreateOptionsModal = (props: HomepageCreatePopupModalProps = {}): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.HOMEPAGE_CREATE_OPTIONS_MODAL,
      panelProps: props,
    })
  );
};
