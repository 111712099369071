import type {BasePanel} from '@Components/panel/panel.types';

export const PANEL_ID = 'homepage-create-popup-panel';
export interface HomepageCreatePopupPanelProps extends BasePanel {
  onClickCreateDesign?: VoidFunction;
  onClickCreateSocialMediaPost?: VoidFunction;
  onClickCreateEmail?: VoidFunction;
  onClickCreateEvent?: VoidFunction;
  includeHeader?: boolean;
}
