import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import type {HomepageStartCreatingPanelProps} from '@Panels/homepage-start-creating-panel/homepage-start-creating-panel.types';
import {PANEL_ID} from '@Panels/homepage-start-creating-panel/homepage-start-creating-panel.types';
import {useAppSelector} from '@/hooks';
import styles from './homepage-start-creating-modal.module.scss';

export type HomepageStartCreatingModalProps = HomepageStartCreatingPanelProps;

export default function HomepageStartCreatingModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.HOMEPAGE_START_CREATING_MODAL];
  });

  return (
    <Modal
      modalId={MODALS_IDS.HOMEPAGE_START_CREATING_MODAL}
      panelId={PANEL_ID}
      modalMaxWidth="1440px"
      modalWidth="100%"
      modalHeight="511px"
      animatesBottomUp
      isBottomSheetOnMobile
      stickToBottom
      showNonFunctionalDragHandle
      dragHandleClasses={styles.dragHandle}
      className={styles.modalStyles}
    >
      <LazyPanel
        panelDirectoryName={PANEL_ID}
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openHomepageStartCreatingModal = (props: HomepageStartCreatingModalProps = {}): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.HOMEPAGE_START_CREATING_MODAL,
      panelProps: props,
    })
  );
};
